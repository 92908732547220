import React, { useState } from 'react';
import { TextField, EmptyState } from '@shopify/polaris';
import Image from 'next/image';
import { useMutation } from '@tanstack/react-query';
import type { JsonResponse } from '@utils';
import { NEXT_PUBLIC_SHOPIFY_APP_NAME, Bugsnag, fetchRequest } from '@utils';

// == Types ================================================================

// == Constants ============================================================

const DEFAULT_MYSHOPIFY_DOMAIN = '.myshopify.com';
const SHOPIFY_VALIDATION_REGEX = new RegExp(`^[a-z0-9][a-z0-9\\-]*[a-z0-9]${DEFAULT_MYSHOPIFY_DOMAIN}$`, 'i');

// == Functions ============================================================

// == Component ============================================================

export default function Home(): JSX.Element {
  const [shopName, setShopName] = useState('');
  const [error, setError] = useState<string>();

  const { mutate } = useMutation<JsonResponse<{ redirectTo: string }>, unknown, { shop: string }>(
    ['/api/auth'],
    ({ shop }) => {
      return fetchRequest<{ redirectTo: string }>('/api/auth', {
        method: 'POST',
        body: JSON.stringify({ shop }),
      });
    },
    {
      onSuccess({ data }) {
        // TODO: Validate with zod to ensure values are always valid
        if (data?.redirectTo) window.location.href = data.redirectTo;
      },
      onError(requestError) {
        setError('There was an error, please try again.');
        // eslint-disable-next-line no-console
        console.error('Error:', requestError);
        if (requestError instanceof Error) Bugsnag.notify(requestError);
      },
    },
  );

  return (
    <EmptyState
      action={{
        id: 'login-button',
        content: 'Login',
        external: false,
        onAction: (): void => {
          const shopNameWithDomain = shopName.replace(DEFAULT_MYSHOPIFY_DOMAIN, '') + DEFAULT_MYSHOPIFY_DOMAIN;
          setError(undefined);
          if (!SHOPIFY_VALIDATION_REGEX.test(shopNameWithDomain)) {
            setError('Please enter a valid shop name');
            return;
          }

          mutate({ shop: shopNameWithDomain });
        },
      }}
      image="https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg"
      secondaryAction={{
        content: 'Learn more',
        external: true,
        url: 'https://www.flipgive.com/pages/contact',
      }}
    >
      <Image alt="flipgive logo" height={87} src="/flipgive_logo_black.png" width={259} />
      <p>{`To install the ${NEXT_PUBLIC_SHOPIFY_APP_NAME} app, enter your Shopify shop name`}</p>
      <TextField
        autoComplete="off"
        connectedRight={<span className="myshopify-placeholder">{DEFAULT_MYSHOPIFY_DOMAIN}</span>}
        error={error}
        id="shop-name"
        label="Shop name"
        onChange={setShopName}
        value={shopName}
      />
    </EmptyState>
  );
}

// == Styles ===============================================================
